<template>
  <quill-editor
    :value="value"
    @input="$emit('input', $event)"
    ref="quill"
    :options="quillOptions"
    @ready="handleQuillReady"/>
</template>

<script>
  import 'quill/dist/quill.bubble.css'
  import { quillEditor } from 'vue-quill-editor'

  export default {
    name: 'PostQuill',
    props: {
      value: String,

      placeholder: String,
    },
    components: {
      quillEditor,
    },
    methods: {
      handleQuillReady() {
        this.quill.focus()
        this.quill.setSelection(5000)
      },
    },
    computed: {
      quill() {
        if (!this || !this.$refs || !this.$refs.quill) {
          return
        }
        return this.$refs.quill.quill
      },
      quillOptions() {
        return {
          placeholder: this.placeholder,
          theme: 'bubble',
          formats: ['bold', 'italic', 'underline', 'strike', 'code', 'link'],
          modules: {
            toolbar: {
              container: [['bold', 'italic', 'underline', 'strike', 'code', 'link']],
              handlers: {
                code: (value) => {
                  const selection = this.quill.getSelection()
                  this.quill.removeFormat(selection.index, selection.length)
                  this.quill.format('code', value)
                },
                link: (value) => {
                  // const selection = this.quill.getSelection()
                  // this.quill.removeFormat(selection.index, selection.length)
                  if (value) {
                    this.quill.format('code', false)
                    this.quill.theme.tooltip.root.querySelector('input[data-link]').dataset.link = this.$i18n.t('post.editorLinkPlaceholder')
                    this.quill.theme.tooltip.edit()
                  } else {
                    this.quill.format('link', false)
                  }
                },
              },
            },
            keyboard: {
              bindings: {
                bold: {
                  shortKey: true,
                  key: 'B',
                },
                italic: {
                  shortKey: true,
                  key: 'I',
                },
                underline: {
                  shortKey: true,
                  key: 'U',
                },
                strike: {
                  shortKey: true,
                  shiftKey: true,
                  key: 'X',
                  handler(e, t) {
                    this.quill.format('strike', !t.format.strike)
                  },
                },
                code: {
                  key: 'M',
                  shortKey: true,
                  shiftKey: true,
                  handler(e, t) {
                    this.quill.removeFormat(e.index, e.length)
                    this.quill.format('code', !t.format.code)
                  },
                },
                link: {
                  key: 'K',
                  shortKey: true,
                  handler: (e, t) => {
                    if (t.format.link) {
                      this.quill.format('link', false)
                    } else {
                      this.quill.format('code', false)
                      this.quill.theme.tooltip.root.querySelector('input[data-link]').dataset.link = this.$i18n.t('post.editorLinkPlaceholder')
                      this.quill.theme.tooltip.edit()
                    }
                  },
                },
              },
            },
            // "emoji-textarea": true,
            // "emoji-shortname": true,
          },
        }
      },
    },
    watch: {
      placeholder(v) {
        if (!this || !this.quill || !this.quill.root) {
          return
        }
        this.quill.root.setAttribute('data-placeholder', v)
      }
    }
  }
</script>

<style lang="stylus">
  .ql-container
    font-family inherit

  .ql-tooltip
    z-index 10

  .ql-editor
    padding .75rem
    font-size 16px

    &.ql-blank:before
      left .75rem
      font-style normal

    strong
      font-weight 500

    code
      font-family monospace
      padding 0 !important
      background-color transparent !important
      color inherit !important

    a
      text-decoration none !important
</style>